import React from "react";

import { Container, Row, Col } from "reactstrap";
import { Player, BigPlayButton } from "video-react";
import LykeFooter from "./LykeFooter";
import LykeOpinion from "./LykeOpinion";


const LykeAboutUs = () => {

  return (
    <>
      <div className="features-7 section-image"  >
        <Col className="mr-auto ml-auto text-center" md="8">
          <h2 className="title" style={{ color: "black", marginTop: "110px" }}>EDULYKE TO PLATFORMA Z
            <span style={{ color: "#e94800", fontWeight: "bold" }}> KURSAMI EDUKACYJNYMI</span></h2>
          <h4 className="description" style={{ color: "#888", marginBottom: "20px" }}>
          Oferujemy szeroki zakres tematyczny warsztatów poruszających problematykę zdrowia psychicznego, uzależnień, umiejętności budowania kompetencji psychospołecznych, aktywności fizycznej czy problemów wychowawczych. Nasza oferta skierowana jest zarówno do przedstawicieli biznesu - specjalistów ds. HR, managerów, dyrektorów jak i uczniów, grona nauczycielskiego, osób pracujących z dziećmi i rodziców. Kursy Edulyke są tworzone przez grono ekspertów - praktyków psychologów pedagogów.
          </h4>
        </Col>
        <Container>
          <Row>
            <Col md="4">
              <div className="info info-hover">
                <div className="icon icon-orange icon-circle">
                  <i className="now-ui-icons ui-2_favourite-28" style={{ color: "#e94800" }}></i>
                </div>
                <h5 className="info-title" style={{ color: "black" }}>DOPASOWANE</h5>
                <p className="description" style={{ color: "#888" }}>
                  Nasze kursy są przygotowane z uwzględnieniem potrzeb każdej z trzech grup odbiorców - uczniów, nauczycieli i osób pracujących z dziećmi i młodzieżą oraz rodziców i opiekunów.
                </p>
              </div>
            </Col>
            <Col md="4">
              <div className="info info-hover">
                <div className="icon icon-orange icon-circle">
                  <i className="now-ui-icons ui-2_settings-90" style={{ color: "#e94800" }}></i>
                </div>
                <h5 className="info-title" style={{ color: "black" }}>UŻYTECZNE</h5>
                <p className="description" style={{ color: "#888" }}>
                  Uczestnicy podczas kursów zdobywają nie tylko wiedzę, ale również konkretne umiejętności, m.in. narzędzia ułatwiające dalszy rozwój kompetencji miękkich.                      </p>          </div>
            </Col>
            <Col md="4">
              <div className="info info-hover">
                <div className="icon icon-orange icon-circle">
                  <i className="now-ui-icons business_globe" style={{ color: "#e94800" }}></i>
                </div>
                <h5 className="info-title" style={{ color: "black" }}>DOSTĘPNE</h5>
                <p className="description" style={{ color: "#888" }}>
                  Platforma online daje możliwość dostępu z każdego miejsca i o każdej porze. Dodatkowo EduLyke charakteryzuje przejrzysta, intuicyjna nawigacja i przystępne prezentowanie treści.
                </p>
              </div>
            </Col>
          </Row>

        </Container>
        <Container>
          <Col className="mr-auto ml-auto text-center" md="8">
            <h2 className="title" style={{ color: "black", marginBottom: "60px", marginTop: "30px" }}>ZOBACZ JAK WYGLĄDA
              <span style={{ color: "#e94800", fontWeight: "bold" }}> PLATFORMA</span></h2>
          </Col>
          <Row>

            <Col md="12">
              <div className="iframe-container">
                <Player width={40}>
                  <source src="video/prezentacja.mp4" />
                  <BigPlayButton position="center" />
                </Player>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <LykeOpinion />
      <LykeFooter />
    </>
  )
}

export default LykeAboutUs;