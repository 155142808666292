import React from "react";
import {
  Container
} from "reactstrap";

const LykeHeader = () => {
  return (
    <>
             
        <div className="page-header header-filter" style={{
              backgroundImage:
                "url(" + require("assets/img/edulykemain.jpg") + ")",
                backgroundSize: "cover",
                backgroundPosition: "center 30%",
                  height: '85vh',
            }} >
            <Container>
                <span className="headerTitle2">Edu<b>LYKE</b></span>
            </Container>
        </div>    </>
  );
};

export default LykeHeader;
