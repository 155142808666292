import React from "react";

import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";


const LykeSection = () => {
  return (
    <div className="about-description text-center">
      <div className="features-3">
        <Container>
          <Row>
            <Col className="mr-auto ml-auto" md="12">
              <h2 className="title" style={{ marginTop: "45px" }}>
                <span style={{ color: "#e94800", fontWeight: "bold" }}>
                  EDULYKE
                </span>
                &nbsp;to platforma z kursami edukacyjnymi.
              </h2>
              <h4 className="description" style={{ marginBottom: "40px" }}>

                Znajdziesz tu warsztaty dotyczące rozwoju kompetencji miękkich, zdrowia psychicznego, profilaktyki uzależnień, budowania kompetencji psychospołecznych, i wychowawczych. Trafiamy do uczniów, grona nauczycielskiego, osób pracujących z dziećmi i rodziców. Nasze kursy są autorskie i tworzone przez zespoły ekspertów w dziedzinach psychologii i pedagogiki. Zapraszamy do zapoznania się ze
                <Link className="hiperLyke" to="/oferta">
                <span> szczegółami oferty EduLyke</span> 
                </Link>
                .
              </h4>
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <div className="info info-hover">
                <div className="icon icon-orange icon-circle">
                  <i style={{ fontStyle: "inherit" }}>1</i>
                </div>
                <h4 className="info-title">EDULYKE DAJE EFEKTY!</h4>
                <p className="description">
                  Mierzone imiennymi certyfikatami, quizami
                  sprawdzającymi uzyskaną wiedzę i raportami podsumowującymi
                  zrealizowany program.
                </p>
              </div>
            </Col>
            <Col md="3">
              <div className="info info-hover">
                <div className="icon icon-orange icon-circle">
                  <i style={{ fontStyle: "inherit" }}>2</i>
                </div>
                <h4 className="info-title">EDULYKE DAJE DOSTĘPNOŚĆ!</h4>
                <p className="description">
                  Platforma nie wymaga dodatkowego oprogramowania. Kursy aktywne
                  są całą dobę i z każdego miejsca na świecie.
                </p>
              </div>
            </Col>
            <Col md="3">
              <div className="info info-hover">
                <div className="icon icon-orange icon-circle">
                  <i style={{ fontStyle: "inherit" }}>3</i>
                </div>
                <h4 className="info-title">EDULYKE DAJE UMIEJĘTNOŚCI!</h4>
                <p className="description">
                  Kursy składają się z modułów teoretycznych, warsztatowych i
                  projektowych. Użytkownik zdobywa nie tylko wiedzę, ale również
                  narzędzia do jej wdrożenia.
                </p>
              </div>
            </Col>
            <Col md="3">
              <div className="info info-hover">
                <div className="icon icon-orange icon-circle">
                  <i style={{ fontStyle: "inherit" }}>4</i>
                </div>
                <h4 className="info-title">EDULYKE DAJE WSPARCIE!</h4>
                <p className="description">
                Zarówno pojedynczym użytkownikom kursów - budując ich zasoby, jak i organizacjom wspierającym rozwój jednostek - dając im gotowe narzędzie do rozwoju osobistego.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default LykeSection;
