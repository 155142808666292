import { Button, Card, Col, Container, Row } from "reactstrap";
import React from "react";

const WhyImportant = () => {
  return (
    <div
      style={{
        backgroundImage: "url(" + require("assets/img/ekran3.jpg") + ")",
        backgroundPosition: "center",
        backgroundSize: "cover",
        position: "relative",
        minHeight: "100vh",
      }}
      id='whyB2b'
    >
      <div className={"bg-overlay-lower"}></div>
      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-center" md="8">
          <h1 className="importantTitle">DLACZEGO TEN PROGRAM<br/>JEST WAŻNY?</h1>
          </Col>
        </Row>
        <div style={{display: 'flex', justifyContent:'center', gap: '30px', flexWrap: 'wrap'}}>
            <div>
        <div class="card-important">
          <h4>
            10,5 % zwolnień chorobowych ma podstawę w zaburzeniach psychicznych
            i zaburzeniach zachowania.
          </h4>
        </div>

        <div class="card-important">
          <h4>
            Koszty odejścia pracownika z firmy to 33% jego rocznego
            wynagrodzenia.
          </h4>
        </div>
        </div>
        <div>
        <div class="card-important">
          <h4>
            6 mld zł co roku mogłyby zaoszczędzić polskie firmy, gdyby
            zapewniały wsparcie psychiczne pracownikom.
          </h4>
        </div>

        <div class="card-important">
          <h4>
            Od 2022 wypalenie zawodowe jest wpisane na listę chorób i może być
            podstawą do L4.
          </h4>
        </div>
        </div>
        </div>
      </Container>
    </div>
  );
};

export default WhyImportant;
