import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

const LykePreFooter = () => {
  return (
    <>
        <Row style={{width: '100%'}}>
        <Col className="ml-auto mr-auto text-center" md="8">
          <h2
            className="title preFooterEdulyke"
            style={{
              marginBottom: "20px",
              fontSize: "21px",
            }}
          >
            Masz pytania dotyczące <span style={{ color: "#e94800", fontWeight: "bold" }}>EduLyke</span>
            ?
          </h2>
        </Col>
        <Col className="text-center preFooterTxt ml-auto mr-auto mt-5" md="8">
        </Col>
        <Col className="text-center ml-auto mr-auto preFooterEdulyke" md="8" style={{ marginBottom: "40px" }}
>
          <div style={{ textAlign: "center" }}>
            <Link
              className="preFooterBtn"
              style={{ margin: "20px auto" }}
              to={`/kontakt`}
            >
              SKONTAKTUJ SIĘ
            </Link>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default LykePreFooter;
