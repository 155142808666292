import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import { BsArrowUpSquare } from "react-icons/bs";
import { Link } from "react-scroll";

import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import React from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const LastPage = () => {
  const [form, setForm] = React.useState({});

  const sendMessage = () => {
    axios
      .post("https://netluck.pl:9030/edulyke/mail", form)
      .then(() => {
        toast.success("Wiadomość wysłana");

        setForm({});
      })
      .catch(() => {
        toast.error("Wystąpił problem - prosimy spróbować później");
      });
  };

  const [nameFocus, setNameFocus] = React.useState(false);
  const [lastnameFocus, setLastameFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const formChange = (e) => {
    const { name, value } = e.target;
    const tmp = { ...form };
    tmp[name] = value;
    setForm(tmp);
  };

  return (
    <div style={{ backgroundColor: "#17181a" }}>
      <div className="projects-5" id='contact'>
        <Container>
          <Row>
            <Col className="ml-auto" md="7">
              <img
                src={require("assets/img/woman1.jpg")}
                style={{
                  borderRadius: "10px",
                  marginBottom: "10px",
                  marginTop: "60px",
                  maxWidth: "100%",
                }}
                className="contactImage"
              />
            </Col>
            <Col className="mr-auto" md="5" style={{ marginTop: "20px" }}>
              <div className=" info-horizontal">
                <div className="description">
                  <h2
                    style={{
                      color: "#ed5d01",
                      textAlign: "left",
                      fontSize: "3.2em",
                    }}
                    className="info-title"
                  >
                    Zadbajmy wspólnie o Wasze środowisko pracy.
                  </h2>
                  <h3 style={{ color: "white", textAlign: "left" }}>
                    ZAPRASZAMY DO KONTAKTU!
                  </h3>
                </div>
              </div>
              <div className=" info-horizontal">
                <div
                  className=" info-horizontal"
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <Card
                    className="card-contact card-raised"
                    style={{
                      borderRadius: "10px",
                      paddingTop: "25px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      width: "90%",
                    }}
                  >
                    <Form id="contact-form" method="post" role="form">
                      <label>Imię</label>
                      <InputGroup
                        className={nameFocus ? "input-group-focus" : ""}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons users_circle-08"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="name"
                          placeholder="Imię..."
                          type="text"
                          required
                          onChange={formChange}
                          onFocus={(e) => setNameFocus(true)}
                          onBlur={(e) => setNameFocus(false)}
                        ></Input>
                      </InputGroup>
                      <label>Nazwisko</label>
                      <InputGroup
                        className={lastnameFocus ? "input-group-focus" : ""}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons text_caps-small"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="lastname"
                          placeholder="Nazwisko..."
                          type="text"
                          onChange={formChange}
                          onFocus={(e) => setLastameFocus(true)}
                          onBlur={(e) => setLastameFocus(false)}
                          required
                        ></Input>
                      </InputGroup>
                      <label>Adres e-mail</label>
                      <InputGroup
                        className={emailFocus ? "input-group-focus" : ""}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons ui-1_email-85"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="email"
                          placeholder="Email..."
                          type="email"
                          onChange={formChange}
                          required
                          onFocus={(e) => setEmailFocus(true)}
                          onBlur={(e) => setEmailFocus(false)}
                        ></Input>
                      </InputGroup>
                      <FormGroup>
                        <label>Wiadomość</label>
                        <Input
                          id="message"
                          name="message"
                          placeholder="Twoja wiadomość..."
                          rows="6"
                          type="textarea"
                          onChange={formChange}
                          required
                        ></Input>
                      </FormGroup>
                      <div className="submit text-center">
                        <Button
                          className="btn-raised btn-round contactBtn"
                          style={{
                            backgroundColor: "#e94800",
                            fontWeight: "bold",
                          }}
                          defaultValue="Contact Us"
                          onClick={sendMessage}
                        >
                          Wyślij
                        </Button>
                      </div>
                    </Form>
                  </Card>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <div className="arrowUpContainer" >
            <Link to="landing" smooth>
              <BsArrowUpSquare className="arrowUpIcon" />
            </Link>
          </div>
        </Container>
      </div>

      <Toaster />
    </div>
  );
};

export default LastPage;
