import React from "react";
import { Link } from "react-router-dom";
import LykeFooter from "./LykeFooter";
import "../assets/css/LykeOffer.css"

import ParentIcon from "../assets/img/parent.png";
import TutorIcon from "../assets/img/tutor.png";
import StudentIcon from "../assets/img/student.png";
import LykeAdults from "./LykeAdults";
import LykeTutor from "./LykeTutor";

class LykeOffer extends React.Component {
  openFunction(functionName, evt) {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("activeOffer", "");
    }
    if (document.getElementById(functionName)) {
      document.getElementById(functionName).style.display = "flex";
    }
    if (evt && evt.currentTarget) {
      evt.currentTarget.className += " activeOffer";
    }
  }

  render() {
    ;
    return (
        <>
          <div
              className="section functions-padding how-it-works"
              style={{ marginTop: "50px" }}
          >
            <div className="container">
              <div className="row">
                <h2 style={{ margin: "5px auto", fontFamily: "Montserrat", fontSize: "2.5em", fontWeight:"400" }}>POZNAJ <span style={{color:"rgb(233, 72, 0)", fontWeight:"bold"}}>KURSY</span> EDULYKE</h2>
                <p className="hiw-teaser">
                  Przygotowaliśmy 3 ścieżki programu EduLyke, uwzględniając
                  zróżnicowane potrzeby i problemy odbiorców. Wybierz grupę, aby
                  poznać program kursów.
                </p>
                <div className="tab hiw-tab">
                  <button
                      className="tablinks col-4 activeOffer"
                      onClick={(event) => {
                        this.openFunction("chat")
                        event.currentTarget.classList.toggle('activeOffer');
                      }}
                  >
                    <img src={StudentIcon} /> <br />
                    <span className="fn-title hidden-sm">
                    <br />
                    EDULYKE DLA UCZNIÓW
                  </span>
                  </button>
                  <button
                      className="tablinks col-4"
                      onClick={(event) => {
                        this.openFunction("payment");
                        event.currentTarget.classList.toggle('activeOffer');
                      }}
                  >
                    <img src={TutorIcon} /> <br />
                    <span className="fn-title hidden-sm">
                    EDULYKE DLA OSÓB PRACUJĄCYCH Z DZIEĆMI I MŁODZIEŻĄ
                  </span>
                  </button>
                  <button
                      className="tablinks col-4"
                      onClick={(event) => {
                        this.openFunction("absence");
                        event.currentTarget.classList.toggle('activeOffer');
                      }}
                  >
                    <img src={ParentIcon} /> <br />
                    <span className="fn-title hidden-sm">
                    <br />
                    EDULYKE DLA RODZICÓW I OPIEKUNÓW
                  </span>
                  </button>
                </div>
                <div id="chat" style={{ padding: "50px" }} className="tabcontent">
                  <div className="item activeOffer wrap wrap-min-margin offerWrap1">
                    <div className="row a hiw-faq-carousel-wrapper">
                      <div>
                        <p className="hiw-faq-title">EDULYKE DLA UCZNIÓW</p>

                        <div className="hiw-faq-part middle-flex">
                          <div className="hiw-faq-part-text">
                            <p className="hiw-faq-part-title">
                              10 KURSÓW WCHODZĄCYCH W SKŁAD PROGRAMU:
                            </p>
                            <p className="hiw-faq-part-info">
                              1. Profilaktyka uzależnień alkoholowych
                              <br />
                              2. Alkohol? Nie, dziękuję - warsztaty z projektowania
                              <br />
                              3. Profilaktyka uzależnień narkotykowych
                              <br />
                              4. Narkotykom mówię nie - zajęcia projektowe
                              <br />
                              5. Profilaktyka uzależnień behawioralnych
                              <br />
                              6. Profilaktyka uzależnień behawioralnych - zajęcia projektowe
                              <br />
                              7. Warsztat Asertywności
                              <br />
                              8. Warsztat Pewności Siebie
                              <br />
                              9. Warsztat Komunikacji w Grupie
                              <br />
                              10. Warsztat Wyznaczania Celów
                              <br />
                            </p>
                            <p className="hiw-faq-part-title">
                              KURSY BEZPIECZNA PRZYSZŁOŚĆ:
                            </p>
                            <p className="hiw-faq-part-info">
                              1. Stres w szkole
                              <br />
                              2. Napoje energetyczne
                              <br />
                              3. Mowa nienawiści
                              <br />
                              4. Podpuszczanie
                              <br />
                              5. Bezpieczny wypoczynek
                              <br />
                              6. Papierosy i e-papierosy *
                              <br /> <br />
                              * wkrótce dostępne
                            </p>
                          </div>
                        </div>
                        <div className="hiw-faq-part middle-flex">
                          <div className="hiw-faq-part-text">
                            <p className="hiw-faq-part-title">WAŻNE INFORMACJE</p>
                            <p className="hiw-faq-part-info">
                              <ul style={{ paddingLeft: "15px" }}>
                                <li>
                                  program przeznaczony dla uczniów klas IV-VIII
                                  szkół podstawowych
                                </li>
                                <li>
                                  forma programu: kursy on-line na autorskiej,
                                  bezpiecznej platformie edukacyjnej
                                </li>
                                <li>
                                  każdy kurs zawiera quiz, który uczeń musi zdać
                                  na 100% (ma na to aż 5 podejść)
                                </li>
                                <li>
                                  nauczyciel otrzymuje konspekty zajęć, odpowiedzi
                                  do testów, instrukcję obsługi kursu i
                                  przykładowy scenariusz zajęć do realizacji
                                  stacjonarnej i zdalnej
                                </li>
                                <li>
                                  prosty system logowania, intuicyjna obsługa
                                </li>
                                <li>
                                  kursy urozmaicone są filmami, grafikami,
                                  ćwiczeniami i angażującymi zabawami
                                </li>
                                <li>
                                  narracja kursu prowadzona przez pedagoga,
                                  umożliwiająca bezproblemową samodzielną
                                  realizację materiału przez ucznia
                                </li>
                              </ul>
                            </p>
                          </div>
                        </div>
                        <div className="hiw-faq-part middle-flex">
                          <div className="hiw-faq-part-text">
                            <p className="hiw-faq-part-title">CELE PROGRAMU</p>
                            <p className="hiw-faq-part-info">
                              <ul style={{ paddingLeft: "15px" }}>
                                <li>Zwiększenie świadomości na temat zachowań
                                  szkodliwych dla zdrowia.</li>
                                <li>Rozbicie błędnych normatywnych przekonań
                                  dotyczących uzależnień i przyjmowania alkoholu i
                                  substancji psychoaktywnych.</li>
                                <li>Wzmocnienie odporności psychicznej uczniów i
                                  poczucia sensu poprzez wsparcie rozwoju umiejętności
                                  społecznych.</li>
                                <li>Promocja zdrowego stylu życia wolnego od
                                  uzależnień.</li>
                                <li>Poprawa jakości życia i relacji
                                  interpersonalnych.</li>
                              </ul>
                            </p>
                          </div>
                        </div>
                        <div style={{ textAlign: "center" }}>
                          <Link
                              className="offerBtn violet-offerBtn"
                              style={{
                                margin: "20px auto",
                                textDecoration: "none",
                              }}
                              to={`/kontakt`}
                          >
                            Zapytaj o indywidualną wycenę
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                    id="payment"
                    style={{ padding: "50px" }}
                    className="tabcontent"
                >
                  <div className="item activeOffer wrap wrap-min-margin offerWrap">
                    <div className="row hiw-faq-carousel-wrapper">
                      <div>
                        <p className="hiw-faq-title">
                          EDULYKE DLA OSÓB PRACUJĄCYCH Z DZIEĆMI I MŁODZIEŻĄ
                        </p>
                        <div className="hiw-faq-part middle-flex ">
                          <div className="hiw-faq-part-text ">
                            <p className="hiw-faq-part-title">
                              3 KURSY WCHODZĄCE W SKŁAD PROGRAMU:
                            </p>
                            <LykeTutor />
                          </div>
                        </div>

                        <div className="hiw-faq-part middle-flex">
                          <div className="hiw-faq-part-text">
                            <p className="hiw-faq-part-title">CELE PROGRAMU:</p>
                            <p className="hiw-faq-part-info">                            <ul style={{ paddingLeft: "15px" }}>
                              <li>Udzielenie wsparcia nauczycielom i pedagogom pracującym z dziećmi poprzez udostępnienie informacji na temat reagowania na mowę nienawiści..</li>
                              <li>Wzmocnienie odporności psychicznej nauczycieli i pedagogów poprzez poprawę umiejętności radzenia sobie z przejawami agresji przejawiającej się jako mowa nienawiści.</li>
                              <li>Wzmocnienie odporności psychicznej uczniów i
                                poczucia sensu poprzez wsparcie rozwoju umiejętności
                                społecznych.</li>
                              <li>Zwiększenie świadomości nauczycieli i pedagogów na temat najnowszych uzależnień rozprzestrzeniających się wśród dzieci i młodzieży.</li>
                              <li>Zwiększenie kompetencji nauczycieli i pedagogów w zakresie pracy z emocjami ucznia.</li>
                            </ul>
                            </p>
                          </div>
                        </div>
                        <div style={{ textAlign: "center" }}>
                          <Link
                              className="offerBtn violet-offerBtn"
                              style={{ margin: "20px auto" }}
                              to={`/kontakt`}
                          >
                            Zapytaj o indywidualną wycenę
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                    id="absence"
                    style={{ padding: "50px" }}
                    className="tabcontent"
                >
                  <div className="item activeOffer wrap wrap-min-margin offerWrap">
                    <div className="row hiw-faq-carousel-wrapper">
                      <div>
                        <p className="hiw-faq-title">
                          EDULYKE DLA RODZICÓW I OPIEKUNÓW
                        </p>
                        <p className="hiw-faq-part-title">
                          2 KURSY WCHODZĄCE W SKŁAD PROGRAMU:
                        </p>
                        <div className="hiw-faq-part middle-flex">
                          <div className="hiw-faq-part-text">
                            <LykeAdults />
                            <p className="hiw-faq-part-title">
                              INFORMACJE DODATKOWE:
                            </p>
                            <p className="hiw-faq-part-info">
                              <ul style={{ paddingLeft: "15px" }}>
                                <li>
                                  formuła kursów z zakresu uzależnień jest
                                  napisana bardzo przystępnym językiem
                                </li>
                                <li>
                                  kursy zawierają przykłady i opisy sytuacji
                                </li>
                              </ul>
                            </p>
                          </div>
                        </div>
                        <div className="hiw-faq-part middle-flex">
                          <div className="hiw-faq-part-text">
                            <p className="hiw-faq-part-title">CELE PROGRAMU:</p>
                            <p className="hiw-faq-part-info">
                              <ul style={{ paddingLeft: "15px" }}>
                                <li>Zwiększenie świadomości rodziców i opiekunów na
                                  temat uzależnień wśród dzieci i młodzieży z
                                  naciskiem na sposoby rozpoznawania i reagowania.</li>
                                <li>Zwiększenie kompetencji rodziców i opiekunów w
                                  zakresie umiejętności komunikacyjnych z dzieckiem.</li>
                                <li>Poprawa relacji rodzica z dzieckiem.</li>
                                <li>Zdobycie umiejętności udzielenia wsparcia dziecku
                                  w zakresie radzenia sobie z jego stresem, lękiem i
                                  emocjami.</li>
                                <li>Lepsze zrozumienie potrzeb dziecka i nauka ich
                                  zaspokajania.</li>
                              </ul>
                            </p>
                          </div>
                        </div>
                        <div style={{ textAlign: "center" }}>
                          <Link
                              className="offerBtn violet-offerBtn"
                              style={{ margin: "20px auto", textAlign: "center" }}
                              to={`/kontakt`}
                          >
                            Zapytaj o indywidualną wycenę
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <LykeFooter />
        </>
    );
  }
}

export default LykeOffer;
